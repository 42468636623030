import { Link } from "gatsby"
import React from "react"
import { Col, Row } from "react-bootstrap"
import SVGIcon from "../common/SVGIcon"

const Featuredcontainer = props => {
  const {
    episodeNumber,
    audioDuration,
    title,
    heading,
    season,
    slug,
    webSlug,
    superpodPage,
    image,
    webImage,
    coverImage,
    subtext,
    webSubtext,
    footerText,
    guestName,
    guestTitle,
    superPodGuestTextTheme,
    tag,
  } = props.data

  let slugprefix

  if (heading === "BLOG") {
    slugprefix = `blog/${slug}`
  } else if (heading === "WEBINAR") {
    slugprefix = `resources/webinar/${webSlug}`
  } else if (heading === "PODCAST") {
    let category =
      superpodPage === "podcast"
        ? "season1"
        : superpodPage === "bytes"
        ? "superpod-bytes"
        : superpodPage === "limitedEdition"
        ? "limited-edition"
        : "season2"
    slugprefix =
      category === "superpod-bytes" || category === "limited-edition"
        ? `${category}/${slug}`
        : `superpod/${category}/${slug}`
  } else {
    slugprefix =
      props.slug === "superpod-bytes" || props.slug === "limited-edition"
        ? `${props.slug}/${slug}`
        : `superpod/${props.slug}/${slug}`
  }
  const formattedTags = tag ? tag.join(" | ") : null

  return (
    <Link to={`/${slugprefix}`}>
      <div
        className={`prelative featured-container episode-cards-common ${props.isFeatured &&
          "feat-con-cta"}`}
      >
        <Row>
          <Col
            className="episode-img"
            md={props.lgLeft ? 5 : props.isFeatured ? 4 : 2}
          >
            <center>
              <div className="cover-container prelative overflow-hidden">
                <img
                  src={
                    props.isFeatured
                      ? image.url
                      : coverImage != null
                      ? coverImage.url
                      : image.url
                  }
                  className="cover w-100 h-100"
                />
                {!props.icon && (
                  <div className="img-overlay w-100 h-100 position-absolute">
                    <div className="play-icon prelative">
                      <SVGIcon name="superpod/playicon-pink-small" />
                    </div>
                  </div>
                )}
              </div>
            </center>
          </Col>
          <Col
            md={props.lgRight ? 7 : props.isFeatured ? 8 : 10}
            className="position-relative d-flex flex-column justify-content-space-between"
            style={{ padding: `${props.col_R_Pad_0 && "0 0 0 4px"}` }}
          >
            <center>
              <div className="content">
                <div>
                  <div className="episode">
                    {/* {season && <span className="e-season">{season}: </span>} */}

                    {heading ? (
                      <span className="feature-type">{heading}</span>
                    ) : (
                      <span className="e-no">EPISODE #{episodeNumber}</span>
                    )}
                  </div>
                </div>
                <span className="e-title fw-bold">
                  {title ? title : subtext ? subtext : webSubtext}
                </span>
                <span className="sub-text position-absolute">{footerText}</span>
              </div>
            </center>
            <center className="down-content">
              <p className="dc-guest-name">{guestName}</p>
              <div className="tags-duration">
                <span className="dc-tags">{formattedTags}</span>
                <span className="dc-duration">{audioDuration} MINS</span>
              </div>
            </center>
          </Col>
        </Row>
      </div>
    </Link>
  )
}
export default Featuredcontainer
