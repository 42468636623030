import React, { useState } from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import parse from 'html-react-parser'
import LazyLoad from "react-lazyload"
import Close from "../../assets/images/superpod/sp-close.png"
import SocialShare from "../../components/social_share"
import Slide from "../utility/slide_content"

function EpisodesView(props) {
  const [aDesc, setaDesc] = useState(true)
  const [aNotes, setaNotes] = useState(false)
  const [vDesc, setvDesc] = useState(true)
  const [vNotes, setvNotes] = useState(false)

  const setaNotesActive = () => {
    setaNotes(true)
    setaDesc(false)
  }

  const setaDescActive = () => {
    setaNotes(false)
    setaDesc(true)
  }

  const setvNotesActive = () => {
    setvNotes(true)
    setvDesc(false)
  }

  const setvDescActive = () => {
    setvNotes(false)
    setvDesc(true)
  }

  const {
    title,
    whatYouWillLearn,
    highlights,
    embedded,
    type,
    seo,
    slug,
  } = props.list
  const twitterHandle = props.twitterHandle
  const url = props.url
  const shareTitle = seo.title
  const shareTags = []

  return (
    <>
      <div>
        {type === "Audio" ? (
          <>
            <section className="sp-pop-content">
              <Link className="no-deco" to={`/${props.slug}`}>
                <LazyLoad throttle={200} height={200}>
                  <img
                    src={Close}
                    className="close objcvr"
                    alt="Close"
                    width="8"
                    height="8"
                  />
                </LazyLoad>
              </Link>
              <center>
                {/* <div className="audio-podcast-section"> 
                            <Row> 
                                <Col md={5} className="mt32">

                                <div
                                data-sal="slide-up"
                                data-sal-delay="200"
                                data-sal-easing="ease-out-bounce"
                                data-sal-duration="700"
                                >
                                    <div className="sp-podcast-block">
                                        <div className={`sp-podcast-image ${superPodGuestTextTheme == "Dark" ? "dark" : "light"}`}>
                                            <img src={image.url} alt={title} />
                                            <div className="place-top">
                                                <p> Episode {episodeNumber} </p>
                                            </div>
                                            <div className="place-bottom">
                                                <h3> {guestName} </h3>
                                                <p> {guestTitle} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                </Col>
                                <Col md={7} className="mt40">

                                <div
                                data-sal="slide-up"
                                data-sal-delay="200"
                                data-sal-easing="ease-out-bounce"
                                data-sal-duration="700"
                                >
                                    <div className="sp-podcast-block">
                                        {parse(embedded.html)}
                                        <div className="action-links mt10">
                                            <span> Listen on: </span>
                                            <Feed />
                                        </div>
                                    </div>
                                </div>

                                </Col>
                            </Row>

                        </div> */}

                <div className="audio-content">
                  <section className="sp-podcasts">
                    <Container>
                      <div className="mt32">
                        <Slide delay="200">
                          <div className="sp-podcast-block">
                            {parse(embedded.html)}
                            <div className="action-links">
                              {/* <span> Listen on: </span> */}
                              {/* <SocialLinks type="superpod"/> */}
                            </div>
                          </div>
                        </Slide>
                      </div>
                      <Slide delay="200">
                        <span className="content">
                          <h3> {title} </h3>
                        </span>
                      </Slide>
                    </Container>
                  </section>
                  <div className="ml12 mt10 display-in dsk">
                    <Slide delay="200">
                      <SocialShare
                        socialConfig={{
                          twitterHandle,
                          config: {
                            url: `${url}${props.slug}/${slug}`,
                            title: shareTitle,
                          },
                        }}
                        tags={shareTags}
                        direction="hor"
                      />
                    </Slide>
                  </div>

                  <div className="ml12 mt10 display-in mob aud-mob">
                    <Slide delay="200">
                      <SocialShare
                        socialConfig={{
                          twitterHandle,
                          config: {
                            url: `${url}${props.slug}/${slug}`,
                            title: shareTitle,
                          },
                        }}
                        tags={shareTags}
                        direction="vrt"
                      />
                    </Slide>
                  </div>

                  <div className="display-in aud-right">
                    <Slide delay="200">
                      <div className="ctabs">
                        <div
                          className="tbct"
                          style={ aDesc && { color:'#FF0055', textDecoration: 'underline'}}
                          onClick={setaDescActive}
                          onKeyDown={setaDescActive}
                          role="button"
                          tabIndex={0}
                        >
                          Show notes
                        </div>

                        <div
                          className="tbct"
                          style={ aNotes && { color:'#FF0055', textDecoration: 'underline'}}
                          onClick={setaNotesActive}
                          onKeyDown={setaNotesActive}
                          role="button"
                          tabIndex={0}
                        >
                          Highlights
                        </div>

                        {aDesc && (
                          <>
                            <span className="content">
                              {!!whatYouWillLearn.html && (
                                <> {parse(whatYouWillLearn.html)} </>
                              )}
                            </span>
                          </>
                        )}

                        {aNotes && (
                          <>
                            <span className="content">
                              {!!highlights.html && (
                                <> {parse(highlights.html)} </>
                              )}
                            </span>
                          </>
                        )}
                      </div>
                    </Slide>
                  </div>
                </div>
              </center>
            </section>
          </>
        ) : (
          <>
            <section className="sp-pop-content">
              <Container>
                <Link className="no-deco" to={`/${props.slug}`}>
                  <LazyLoad throttle={200} height={200}>
                    <img
                      src={Close}
                      className="close objcvr"
                      alt="Close"
                      width="8"
                      height="8"
                    />
                  </LazyLoad>
                </Link>
                <center>
                  <div className="video-content">
                    <Slide delay="200">
                      <h3 className="text-left"> {title} </h3>
                    </Slide>
                    <Slide delay="200">{parse(embedded.html)}</Slide>
                    <div className="ml12 mt10">
                      <Slide delay="200">
                        <SocialShare
                          socialConfig={{
                            twitterHandle,
                            config: {
                              url: `${url}${props.slug}/${slug}`,
                              title: shareTitle,
                            },
                          }}
                          tags={shareTags}
                          direction="vtr"
                        />
                      </Slide>
                    </div>
                    <Slide delay="200">
                      <div className="mt60 ctabs">
                        <div
                          className="tbct"
                          style={ vDesc && { color:'#FF0055', textDecoration: 'underline'}}
                          onClick={setvDescActive}
                          onKeyDown={setvDescActive}
                          role="button"
                          tabIndex={0}
                        >
                          Show notes
                        </div>

                        <div
                          className="tbct"
                          style={ vNotes && { color:'#FF0055', textDecoration: 'underline'}}
                          onClick={setvNotesActive}
                          onKeyDown={setvNotesActive}
                          role="button"
                          tabIndex={0}
                        >
                          Highlights
                        </div>

                        {vDesc && (
                          <>
                            <span className="content">
                              {!!whatYouWillLearn.html && (
                                <> {parse(whatYouWillLearn.html)} </>
                              )}
                            </span>
                          </>
                        )}

                        {vNotes && (
                          <>
                            <span className="content">
                              {!!highlights.html && (
                                <> {parse(highlights.html)} </>
                              )}
                            </span>
                          </>
                        )}
                      </div>
                    </Slide>
                  </div>
                </center>
              </Container>
            </section>
          </>
        )}
      </div>
    </>
  )
}

export default EpisodesView
