import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { Col } from "react-bootstrap"
import MillionCard from "./OnemillionCard"
import Featuredcontainer from "./OnemillionContainer"

const featuredVideo = (props) => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query featuredMillion {
            SuperOps {
              episodes(orderBy:episodeNumber_DESC) {
                id
                season
                superpodPage
                episodeNumber
                isFeatured
                coverImage {
                  url(
                    transformation: {document: {output: {format: webp}}}
                  )
                }
                image {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
                title
                summary {
                  html
                }
                whatYouWillLearn {
                  html
                }
                highlights {
                  html
                }
                embedded {
                  html
                }
                guestName
                guestTitle
                audioFile {
                  url
                }
                audioDuration
                type
                superPodGuestTextTheme
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                slug
              }
            }
          }
        `}
        render={data => {
          const firstepisode = data.SuperOps.episodes.filter(episode => episode.superpodPage === props.type)
          return (
            <div className="d-flex">
              <Col md={5} className="prelative mt-0">
                <MillionCard data={firstepisode[0]} slug={props.slug} />
              </Col>
              <Col md={7} className="prelative ">
                {data.SuperOps.episodes.filter(episode => episode.superpodPage === props.type).slice(1, 4).map(episode => {
                  return <Featuredcontainer data={episode} slug={props.slug} />
                })}
              </Col>
            </div>
          )
        }
        }
      />
    </>
  )
}

export default featuredVideo
