import { useMatch } from "@reach/router"
import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import Frame from "../../components/common/frame"
import Buttons from "../common/button"
import EpisodeView from "../superpod/episodes_view"
import MillionCard from "./OnemillionCard"

function EpisodesVideo(props) {
  const [itemsShown, setItemsShown] = useState(1)

  const [itemsToBeShown, setItemsToBeShown] = useState(6)

  const [listCount, setListCount] = useState(0)
  const [sliceVal, setsliceVal] = useState(0)

  const location = useMatch("/superpod-million/:id")

  var currentItem = 0

  return (
    <>
      <div className={`${sliceVal !== 0 && 'd-dsk'}`}>
        <p className="podcast-title">{sliceVal !== 0 ? 'other episodes' : 'episodes'}</p>
      </div>
      <StaticQuery
        query={graphql`
          query EpisodesMillion {
            SuperOps {
              episodes(
                orderBy: episodeNumber_DESC
              ) {
                id
                season
                superpodPage
                type
                audioDuration
                episodeNumber
                image {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 540 } }
                    }
                  )
                }
                title
                guestName
                guestTitle
                superPodGuestTextTheme
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                slug
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <Row className="card-gap">
            {data.SuperOps.episodes.filter(episode => episode.superpodPage === props.type).length >= 5 && setsliceVal(4)}
            {setListCount(data.SuperOps.episodes.filter(episode => episode.superpodPage === props.type).slice(sliceVal).length)}

            {data.SuperOps.episodes.filter(episode => episode.superpodPage === props.type).slice(sliceVal).map(episode => {
              const {
                id,
                image,
                title,
                season,
                audioDuration,
                episodeNumber,
                guestName,
                guestTitle,
                superPodGuestTextTheme,
                seo,
                slug,
              } = episode
              const recentItemsList = data.SuperOps.episodes.filter(
                item => item.id !== id
              )
              const recentItems = recentItemsList.slice(1).slice(-2)
              const twitterHandle = data.site.siteMetadata.twitterHandle
              const url = data.site.siteMetadata.url
              currentItem = currentItem + 1
              return (
                <Col lg={4} md={6} sm={12} style={{
                  display: currentItem <= itemsToBeShown ? "block" : "none",
                }} >
                  <div>
                    {currentItem <= itemsToBeShown
                      ? setItemsShown(currentItem)
                      : null}

                    <MillionCard data={episode} slug={props.slug} />
                  </div>

                  {location && slug === location.id ? (
                    <>
                      <Frame
                        seo={seo}
                        bodyClassName="ofy-hidden bg-black"
                        isFooterVisble="No"
                      />

                      <EpisodeView
                        list={episode}
                        slug="superpod-million"
                        recentItems={recentItems}
                        twitterHandle={twitterHandle}
                        url={url}
                      />
                    </>
                  ) : null}
                </Col>
              )
            })}
          </Row>
        )
        }
      />

      {
        listCount <= 6 || itemsToBeShown >= listCount ? null : (
          <div className="sp-view-more prelative d-flex justify-content-center">
            <Buttons theme="secondary white" text="VIEW MORE" onClick={() => setItemsToBeShown(itemsToBeShown + 6)} />
          </div>
        )
      }
    </>
  )
}

export default EpisodesVideo
