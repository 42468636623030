import { css } from "@emotion/react"
import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Col, Container, InputGroup, Row } from "react-bootstrap"
import BytesLogo from "../assets/images/superpod/bytes-white-logo.png"
import RecessionLogo from "../assets/images/superpod/recession-logo-hero.svg"
import Roadto from "../assets/images/superpod/road-to.svg"
import SuperpodLogo from "../assets/images/superpod/superpod-logo.png"
import CTA from "../components/common/CTA"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import Frame from "../components/common/frame"
import Links from "../components/common/links"
import Navigation from "../components/navigation"
import Episodes from "../components/superpod/episodes_million"
import FeaturedVideo from "../components/superpod/featured_million"
import MillionCard from "../components/superpod/OnemillionCard"
import Slide from "../components/utility/slide_content"
import "../styles/superpodnew.scss"

function SuperPodMillion({ pageContext }) {
  const { data, episodes } = pageContext
  const { ctaBoxes, pageContents, pageSlug, seo, pageName, hero } = data
  return (
    <>
      <StaticQuery
        query={graphql`
          query contentSuperPodPageTemp {
            SuperOps {
              links(where: { section: "Superpod" }) {
                section
                icon {
                  url
                }
                slug
                linkName
              }
            }
          }
        `}
        render={data => (
          <div>
            <div className="superpodmain">
              <div className="pod-main mtm120 bg-black bg-dark">
                <Frame
                  seo={seo}
                  theme="dark"
                  bodyClassName="bg-black"
                  footerClassName="plr superpod-footer"
                >
                  <header>
                    <Navigation page="SuperpodTemplate" />
                  </header>

                  <section
                    className={`${pageName} sp-million-banner hero-banner`}
                    css={css`
                      background-image: url(${hero.backgroundImage.url});
                    `}
                  >
                    <Container className="Layout-container">
                      <center>
                        <div className="content prelative">
                          <Slide style={{ "--sal-delay": "0.2s" }}>
                            {pageName !== "onemillion" &&
                              pageName !== "limitedEdition" && (
                                <img
                                  src={
                                    pageName === "bytes"
                                      ? BytesLogo
                                      : SuperpodLogo
                                  }
                                  className={`${
                                    pageName === "bytes"
                                      ? "bytes-logo"
                                      : pageName === "onemillion"
                                      ? "superpod-logo"
                                      : "superpod-logo-pod"
                                  }`}
                                  alt="logo"
                                />
                              )}
                            {pageName === "onemillion" && (
                              <img
                                src={Roadto}
                                className="million-title prelative"
                              />
                            )}
                            {pageName === "limitedEdition" && (
                              <img
                                src={RecessionLogo}
                                className="million-title prelative"
                              />
                            )}

                            {(pageName === "podcast" ||
                              pageName === "bytes") && (
                              <p className="banner-subtext">
                                {parse(pageContents[0].content.text)}
                              </p>
                            )}
                          </Slide>

                          {ctaBoxes[0].links[0] && (
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <div className="line" />
                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <div className="social-links-banner d-flex justify-content-center flex-wrap">
                                  <Links
                                    className="icons"
                                    items={ctaBoxes[0].links[0]}
                                    iconOnly
                                    name
                                  />
                                </div>
                              </Slide>
                            </Slide>
                          )}
                          <Slide style={{ "--sal-delay": "0.2s" }}>
                            <div className="actions prelative">
                              <InputGroup className="flex-column">
                                <div>
                                  <FormBuilder
                                    formClassName="hero-form"
                                    errorClassName="error-message"
                                    inputClassName="email"
                                    inputErrorClassName="error-active"
                                    buttonText="SUBSCRIBE"
                                    buttonClassName="primary white"
                                    type="subscribe"
                                  />
                                  <p className="subscribe-text">
                                    {parse(pageContents[0].subtext.text)}
                                  </p>
                                </div>
                              </InputGroup>
                            </div>
                          </Slide>
                        </div>
                      </center>
                    </Container>
                  </section>

                  {episodes.filter(episode => episode.superpodPage === pageName)
                    .length >= 5 && (
                    <section className="sp-featured-million prelative">
                      <Slide delay="200">
                        <div className="d-dsk">
                          <Container className="prelative Layout-container ">
                            <div className="group-featured-container">
                              <Row>
                                <Col md={5}>
                                  <p className="f-highlight">
                                    {parse(pageContents[0].tag)}
                                  </p>
                                  <p className="left-title">
                                    {parse(pageContents[0].heading)}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <FeaturedVideo
                                  type={pageName}
                                  slug={pageSlug}
                                />
                              </Row>
                            </div>
                          </Container>
                        </div>
                        <Container className="prelative Layout-container d-mob featured-mob">
                          <div className="">
                            <Row className="card-gap">
                              {episodes
                                .filter(
                                  episode => episode.superpodPage === pageName
                                )
                                .slice(0)
                                .reverse()
                                .slice(0, 4)
                                .map(episode => {
                                  return (
                                    <Col lg={4} md={6} sm={12}>
                                      <MillionCard
                                        data={episode}
                                        slug={pageSlug}
                                      />
                                    </Col>
                                  )
                                })}
                            </Row>
                          </div>
                        </Container>
                      </Slide>
                    </section>
                  )}

                  <section
                    className="sp-podcasts-million"
                    css={css`
                      padding-top: ${pageName === "limitedEdition" && "80px"};
                    `}
                  >
                    <Container className="Layout-container">
                      <Episodes type={pageName} slug={pageSlug} />
                    </Container>
                  </section>

                  <section className="sp-cta-million sp-links-cta">
                    <Slide delay="200">
                      <CTA
                        data={[ctaBoxes[0]]}
                        className="Layout-container"
                        lgRight={6}
                        lgLeft={4}
                      />
                    </Slide>
                  </section>
                </Frame>
              </div>
            </div>
          </div>
        )}
      />
    </>
  )
}

export default SuperPodMillion
